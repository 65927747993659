import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ImageGallery from "../components/image-gallery"

import remark from 'remark'
import remarkHTML from 'remark-html'

import BackgroundDrawings from "../components/background-drawings"


const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark
  const pvBanner = "/home/pv-home-banner.jpg";

  const [shownImageUrl, setShownImageUrl] = useState("");

  function onGalleryImageClicked(image) {
    setShownImageUrl(image);
  }

  return (
    <Layout title="Pancho Villa Restaurant" description="At Pancho Villa Restaurant we serve the finest Mexican cuisine using authentic Mexican herbs and recipes.">
      
      {shownImageUrl != "" &&
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <p className="image">
              <img src={shownImageUrl} alt="Gallery image" />
            </p>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={(e) => setShownImageUrl("")}></button>
        </div>
      }

      <Banner title="Gallery" imageUrl={pvBanner} />

      <BackgroundDrawings>        
        <section className="section">
          <div className="container">
            <ImageGallery imageList={frontmatter.imageList} onClick={onGalleryImageClicked} />
          </div>
        </section>
      </BackgroundDrawings>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        imageList {
          image
        }
      }
    }
  }
`