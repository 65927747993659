import React from "react"
import styles from "./image-gallery.module.css"

export default function ImageGallery(props) {

  const imageList = props.imageList;

  function handleClick(image) {
    props.onClick(image);
  }

  return (
    <div className={styles.gallery}>
      {imageList.map((imageItem, imageIndex) => (
          <div className={styles.galleryImage} key={imageIndex}>
            <a onClick={(e) => handleClick(imageItem.image)}><img src={imageItem.image} alt="Gallery image" /></a>
          </div>
        ))}
    </div>
  )
}